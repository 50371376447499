.infoTile {
  padding-right: 30px;
  width: 240px;
}
@media screen and (max-width: 767px) {
  .infoTile {
    width: 100%;
  }
}
.title {
  color: #767676;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 13px;
  text-transform: uppercase;
}
.confirmed {
  font-size: 32px;
  color: #de3700;
  font-weight: bold;
  line-height: 40px;
  cursor: pointer;
}
.legend {
  display: grid;
  padding: 20px 0;
  grid-template-columns: 8px auto min-content;
  align-items: center;
  grid-gap: 16px 8px;
  font-size: 13px;
  line-height: 20px;
}
.legend-icon {
  width: 8px;
  height: 8px;
}
.total {
  color: #fff;
  text-align: right;
  padding: 0 10px;
  border-radius: 4px;
  cursor:pointer;
}

.active-case {
  background-color: rgb(244, 195, 99);
}
.recovered-case {
  background-color: green;
}
.death-case {
  background-color: red;
}
